.App {
  text-align: center;
  
}
*{
  font-family:KB;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
::-webkit-scrollbar{

  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: #C8EE5B;
}
::-webkit-scrollbar-track{
  background-color: #2C8E2F;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family:KB;
  src: url(./assets/fonts/Kanit-Bold.ttf);
}

@font-face {
  font-family:KM;
  src: url(./assets/fonts/Kanit-Medium.ttf);
}
@font-face {
  font-family:KR;
  src: url(./assets/fonts/Kanit-Regular.ttf);
}
@font-face {
  font-family:KSM;
  src: url(./assets/fonts/Kanit-SemiBold.ttf);
}
@font-face {
  font-family:PSB;
  src: url(./assets/fonts/PlaypenSans-Bold.ttf);
}

.sectionTitle {
  line-height: 56px;
  line-height: .9655172414;
  color: #fff;
  text-align: center;
}
.sectionTitle span {
  font-family: "Quicksand";
  font-weight: 900;
  background: #fff;
}

.textStroke{
  -webkit-text-stroke:2px;
  -webkit-text-stroke-color:#000000;
}
*{
  scroll-behavior: smooth;
}

/* CarouselComponent.css */
.carousel-container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
}

.carousel-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff;
}

.carousel-item {
  position: relative;
  max-width: 460px;
  min-height: 360px;
  margin: 0 10px;
  padding: 0px;
  background-color: #C8EE5B; /* Yellow background */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.carousel-item:hover {
  transform: scale(1.005);
}

.carousel-image {
  width: 100%;
  border-radius: 10px;
}

.carousel-info {
  padding: 0px 0;
  text-align: center;
}

.carousel-info p {
  padding: 0px 20px;
  font-size: 0.9rem;
  color: #000000;
  text-align: left;
}

.carousel-info h3 {
  padding: 0px 20px;
  text-align: left;
  font-size: 1.1rem;
  margin: 5px 0;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  background-color: #C8EE5B; /* Yellow background */
  color: #000; /* Black text for arrow */
  width: 50px;
  height: 50px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
   transition: all 0.2s ease;
     box-shadow: 0px 6px 0px 0px black;
     border: 2px solid black;
}

.custom-next {
  right: 0px;
}

.custom-prev {
  left: 0px;
}

.custom-arrow span {
  font-size: 2rem;
  color: #000;
}

.fade-right-container {
  position: relative;
  overflow: hidden;
}

.fade-right-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 280px;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  /* background: linear-gradient(to right, #40b74b24 0%, #40b74bc7 100%); */
  /* width: 270px; */
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%); */ 
  background: linear-gradient(to right, #40b74b00 0%, #40b74b 100%);
}

@media (max-width: 768px) {
  .fade-right-container:after {

    width: 80px;
  }
  .carousel-item {
    /* padding: 10px; */
  }

  .custom-next, .custom-prev {
    display: none; /* Hide arrows on smaller screens */
  }
}

/* Main container styling */
.main-container {
  padding: 20px;
  background-color: #d4ff5a;
  text-align: center;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

/* Image and text section layout */
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.image-section img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
}

/* Text section */
.text-section {
  background-color: #d4ff5a;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  text-align: left;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
}

/* Responsive design */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .content {
    flex-direction: column;
  }

  .text-section {
    font-size: 1rem;
  }
}

@media (max-width: 599px) {
  .title {
    font-size: 1.5rem;
  }

  .text-section {
    font-size: 0.9rem;
  }
}
@keyframes flip {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  25% {
    transform: rotateY(180deg) rotateX(0deg);
  }
  50% {
    transform: rotateY(180deg) rotateX(180deg);
  }
  75% {
    transform: rotateY(0deg) rotateX(180deg);
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg);
  }
}

.logo-flip {
  width: 40px; /* Adjust the size as per your need */
  height: 40px;
  margin: 0 0px 0 15px;
  animation: flip 3s infinite linear;
}
@media (max-width: 599px) {
  .logo-flip {
    width: 30px; /* Adjust the size as per your need */
    height: 30px;
    margin: 0  0 0 15px ;
    animation: flip 3s infinite linear;
  }
}